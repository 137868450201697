class Wine {
 
    #nFactor = 0;
    
    constructor (quantity, alcoholPercentage) {
    this.quantity = quantity
    this.alcoholPercentage = alcoholPercentage
    this.water = 0
    this.sugar = 0
    this.acid = 0
    this.tannin = 0
    this.fruits = [] // fruints in wine
    this.adds = []

    this.fruitsTable = fruitTab; // all fruits table
  }

  get futitKgAll() {
    const result = this.fruits.reduce((a, b) => a + parseFloat(b.fruitKG), 0) || 0
    return Number(result.toFixed(2));
  }
  get fruitPercent() {
    return this.fruits.reduce((a, b) => a + parseInt(b.percentage), 0) || 0
  }
  
  get fruitPecentOK() {

    const result = this.fruits.reduce((a, b) => a + parseInt(b.percentage), 0) || 100;
    return (result === 100 ? true : false);
   
  }

  addFruit (fruit, percentage, fruitID = 0) {
    const fruitKG = 0; 
    const fruitObj = {fruit, percentage, fruitKG }
    const index = this.fruits.findIndex(elD => elD.fruit === fruit)
    if (fruitID === 0) {
      index === -1 ? this.fruits.push(fruitObj) : this.fruits.splice(index, 1, fruitObj);
    } else {
      const indexOld = this.fruits.findIndex(elD => elD.fruit === fruitID)
      this.fruits.splice(indexOld, 1, fruitObj)
    }

    this._reCalculate();
  }

  removeFruit(fruit) {
    const index = this.fruits.findIndex(elD => elD.fruit === fruit);
    if (index > -1) {
      this.fruits.splice(index, 1);
      this._reCalculate();
    }
    //index > -1 ? this.fruits.splice(index,1) ? this._reCalculate() : '' : '';
  }

  changeQuantity(newQuantity) {
    this.quantity = newQuantity;
    this._reCalculate();
  }

  changeAlcoholPercentage(newAlc) {
    this.alcoholPercentage = newAlc;
    this._reCalculate();
  }

  _reCalculate () {
    this.quantity > 1 ? this.#nFactor = 1 : this.#nFactor = 0;
    this.water = 0
    this.sugar = 0
    this.acid = 0
    this.tannin = 0

    for (const fruit of this.fruits) {
      // Fruits
      const fruitDetails = fruitTab.find(elD => elD.name === fruit.fruit);
      const wineQtyCalcPercent = this.quantity * (Number(fruit.percentage) / 100); // calculate percent
      const fruitsQty = fruitDetails.fruitIn * wineQtyCalcPercent;
      fruit.fruitKG = Number(parseFloat(fruitsQty).toFixed(2));
      // Sugar
      const sugarQtyCalc =  (wineQtyCalcPercent * this.alcoholPercentage * 0.01 * 1.67 - fruitsQty * fruitDetails.sugar) * this.#nFactor
      this.sugar +=  Number(sugarQtyCalc); // Number(parseFloat(sugarQtyCalc).toFixed(2)); // 
      this.sugar = Number(parseFloat(this.sugar).toFixed(2));
      // Water
      this.water +=   wineQtyCalcPercent -   fruitsQty * fruitDetails.water -  sugarQtyCalc * 0.6 * this.#nFactor;
      this.water = Number(parseFloat(this.water).toFixed(2));
      // Acid
      this.acid += fruitDetails.acid * wineQtyCalcPercent * this.#nFactor;
      this.acid = Number(parseFloat(this.acid).toFixed(2));
      //Tannin
      this.tannin += fruitDetails.tannin * wineQtyCalcPercent * this.#nFactor;
      this.tannin = Number(parseFloat(this.tannin).toFixed(2));
    }
  }
}

const fruitTab = [
    { name: "n/a", fruitIn: 0, sugar: 0, water: 0, acid: 0, tannin: 0 },
    { name: "agrest", fruitIn: 0.5, sugar: 0.07, water: 0.7, acid: 0, tannin: 0 },
    { name: "aronia", fruitIn: 0.65, sugar: 0.05, water: 0.7, acid: 0, tannin: 0 },
    { name: "czarny bez", fruitIn: 0.45, sugar: 0.04, water: 0.75, acid: 2, tannin: 0 },
    { name: "brzoskwinia", fruitIn: 1, sugar: 0.08, water: 0.7, acid: 1, tannin: 0 },
    { name: "czereśnia", fruitIn: 0.8, sugar: 0.08, water: 0.65, acid: 1, tannin: 0 },
    { name: "dzika róża", fruitIn: 0.25, sugar: 0.01, water: 0.12, acid: 1, tannin: 0 },
    { name: "gruszka", fruitIn: 0.9, sugar: 0.08, water: 0.5, acid: 2.5, tannin: 0.1 },
    { name: "jabłko", fruitIn: 0.8, sugar: 0.08, water: 0.5, acid: 0, tannin: 0 },
    { name: "jagody", fruitIn: 0.55, sugar: 0.05, water: 0.65, acid: 0, tannin: 0 },
    { name: "jeżyny", fruitIn: 0.7, sugar: 0.05, water: 0.6, acid: 0.5, tannin: 0 },
    { name: "maliny", fruitIn: 0.65, sugar: 0.045, water: 0.7, acid: 0, tannin: 0 },
    { name: "pomarańcze", fruitIn: 0.6, sugar: 0.1, water: 0.55, acid: 0, tannin: 0 },
    { name: "porzeczki b. i czerw.", fruitIn: 0.45, sugar: 0.045, water: 0.75, acid: 0, tannin: 0 },
    { name: "porzeczki czarne", fruitIn: 0.35, sugar: 0.065, water: 0.75, acid: 0, tannin: 0 },
    { name: "śliwki", fruitIn: 0.7, sugar: 0.09, water: 0.5, acid: 0, tannin: 0 },
    { name: "truskawki", fruitIn: 0.7, sugar: 0.06, water: 0.7, acid: 2, tannin: 0 },
    { name: "wiśnie", fruitIn: 0.6, sugar: 0.1, water: 0.75, acid: 0, tannin: 0 }
]

export default Wine;