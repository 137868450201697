// import logo from './logo.svg';
// import './App.css';
// import './wine.js';
// import { Button } from 'react-bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
import React, { Component } from 'react'
// import ReactDOM from 'react-dom';
import Pages from './pages';

class App extends Component {

  render() {
    return (
      <div classForm="appMainDiv">
          <Pages />
      </div>
    );
  }
}
export default App;
