import React, { Component } from 'react'

class Header extends Component {
  render () {
    return (
      <div className='header'>
        <nav className='navbar navbar-main navbar-expand-lg bg-white navbar-light position-sticky top-0 shadow py-2'>
          <div className='container-fluid'>
            <a id='logo' className='navbar-brand' href="/">
              <img  src='grape.png'  alt='Logo damionPl'  height='54'/>
            </a>
            dymion.pl
          </div>
        </nav>
      </div>
    )
  }
}

export default Header
