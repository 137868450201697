
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Footer from '../components/Footer'
import Header from '../components/Header'

import Design from './design'
import Home from './home'

let Pages = () => {
  return (
    <div>
      <Header />
        <Router>
          <Routes>
            <Route exact path='/' element={<Design />} />
            <Route path='/home' element={<Home />} />
          </Routes>
        </Router>
      <Footer />
    </div>
  )
}

export default Pages
