import './modalFruit.css'
import { Button, Modal, Form } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useState } from 'react'


function ModalFruit({fn, fruitID = 0 , percent = 0, wineObj, setXXX}) {

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const handleAdd = () => {

      if (!document.querySelector('#fruitSelect').validity.valid) {
        return;
      }
      if (!document.querySelector('#percentIn').validity.valid) {
        return;
      }
        const fruitToAdd = document.querySelector('#fruitSelect').value;
        const percentIn = document.querySelector('#percentIn').value;

        wineObj.addFruit(fruitToAdd, percentIn, fruitID);
        setShow(false);
        setXXX();
    }

    return (
        <>
        {
          fn === "Edycja" ? <button type='button' className='btn btn-warning btn-sm gridBtn' onClick={handleShow}>{fn}</button> :
          <button type='button' className='btn btn-success btn-sm addFrBtn' onClick={handleShow}>{fn}</button>
        }
      <Modal aria-labelledby = 'contained-modal-title-vcenter' centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{fn}</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <div className='form-floating'>
            <select className='form-select' id='fruitSelect' required>
                {
                    wineObj.fruitsTable.map((value, index) =>
                    value.name === "n/a" ? <option selected disabled value="">{value.name}</option> :
                    value.name === fruitID ? <option selected value={value.name}>{value.name}</option> : <option value={value.name}>{value.name}</option>)
                }
            </select>
            <label for='fruitSelect'>Wybierz</label>
          </div>
          <br/>
        <div className="input-group form-floating">
          {
            percent !== 0 ?  <input type="number" class="form-control" id="percentIn" min="1" max="100" defaultValue={percent} required/> :
            <input type="number" class="form-control" id="percentIn" min="1" max="100" defaultValue="100" required/>
          }
            <span className="input-group-text">%</span>
            <label for='percentIn'>Zawartość % w nastawie</label>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>Anuluj</Button>
          <Button variant='primary' onClick={handleAdd} type="submit" >Zapisz</Button>
        </Modal.Footer>
      </Modal>
        </> 
    )
}

export default ModalFruit;