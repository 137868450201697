import '../pages/style.css'
import React, { Component } from 'react'

class Footer extends Component {
  render () {
    return (
      <div>
        <footer className='footer'>
          <br />
          <hr />
          <h6>
            Powered by <img src='logo.svg' alt='Powered by React' height='44' />
          </h6>
        </footer>
      </div>
    )
  }
}

export default Footer
