//import 'bootstrap/dist/css/bootstrap.min.css'
import './style.css'

import React, { useState, useEffect } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import Wine from '../wine.js'
import ModalQuestion from '../components/ModakQ'
import ModalFruit from '../components/ModalFruit'

const Design = () => {
    const [xxx, setXXX] = useState(1);
    let [wineObj, setWine] = useState(new Wine(15, 13));

    setWine = (event) => {

        event.target.id === "qty" ?  wineObj.changeQuantity(event.target.value) : wineObj.changeAlcoholPercentage(event.target.value);
        setXXX(xxx + 1);
      
    }

    useEffect(() => {
      const pecrntColumnH = document.querySelector('#prCol');

      if (!wineObj.fruitPecentOK) {
        pecrntColumnH.classList.add("blink_me");
        NotificationManager.error("Procentowy udział owoców w nastawie powienien wynosić 100% !", "Uwaga !");

      } else {
        pecrntColumnH.classList.remove("blink_me");
      }
      
    });
  return (
    <div className="mainDiv">
      <NotificationContainer/>
      <div>
        <label for='qty' className='form-label'>Ilość wina {wineObj.quantity} (l)</label>
        <input id='qty' type='range' className='form-range' min='1' max='90' step='1' defaultValue="{wineObj.quantity}" onChange={setWine} />
        <label for='alc' className='form-label'>Ilość alkoholu {wineObj.alcoholPercentage} (%)</label>
        <input id='alc' type='range' className='form-range' min='1' max='18' step='1' defaultValue="{wineObj.alcoholPercentage}" onChange={setWine} />
      </div>
      <div>
        <ModalFruit fn='Dodaj owoc' fruitID={0} percent={0} wineObj={wineObj} setXXX={() => setXXX(xxx + 1)} />
      </div>
      <div>
        <table className='table'>
          <thead className='table-light'>
            <tr>
                <th scope='col'>LP</th>
                <th scope='col'>Owoc</th>
                <th scope='col'>Ilość (kg)</th>
                <th scope='col'>%</th>
                <th scope='col'></th>
            </tr>
          </thead>
          <tbody>
            {   wineObj.fruits.map((value, index) =>
                <tr>
                <th scope='row'>{index + 1}</th>
                <td>{value.fruit}</td>
                <td>{value.fruitKG}</td>
                <td>{value.percentage}</td> 
                <td>
                    <ModalFruit fn='Edycja' fruitID={value.fruit} percent={value.percentage} wineObj={wineObj} setXXX={() => setXXX(xxx + 1)} />
                    <ModalQuestion fruitID={value.fruit} wineObj={wineObj} setXXX={() => setXXX(xxx + 1)} />
                </td>
                </tr>)
            }
                <tr>
                  <td></td>
                  <td><b>Razem:</b></td>
                  <td><b>{wineObj.futitKgAll} kg</b></td>
                  <td id='prCol' ><b>{wineObj.fruitPercent} %</b></td>
                  <td></td>
                </tr>
                <tr>
                    <td colSpan="5">
                        <table className="table mb=0">
                            <tr>
                                <th scope='col'>Składnik</th>
                                <th scope='col'>Ilość</th>
                                <th scope='col'>Jednostka</th>
                            </tr>
                            <tr>
                                <td>Woda</td>
                                <td>{wineObj.water}</td>
                                <td>litry</td>
                            </tr>
                            <tr>
                                <td>Cukier</td>
                                <td>{wineObj.sugar}</td>
                                <td>kg</td>
                            </tr>

                            <tr>
                                <td>Kwasek cytrynowy</td>
                                <td>{wineObj.acid}</td>
                                <td>gramy</td>
                                </tr>

                                <tr>
                                <td>Tanina</td>
                                <td>{wineObj.tannin}</td>
                                <td>gramy</td>
                                </tr>

                        </table>
                    </td>
                </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Design
