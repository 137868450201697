import { Button, Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useState } from 'react'


function ModalQuestion({fruitID, wineObj, setXXX}) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const handleDel = () => {
        wineObj.removeFruit(fruitID);
        setShow(false);
        setXXX();
    }


    return (
        <>
            <button type='button' className='btn btn-danger btn-sm gridBtn' onClick={handleShow}>Usuń</button>

            <Modal aria-labelledby = 'contained-modal-title-vcenter' centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                        <Modal.Title>Usuń owoc </Modal.Title>
                </Modal.Header>
            <Modal.Body> 
                <div>
                    Czy na pewno usunąć {fruitID} ?
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>Anuluj</Button>
                <Button variant='primary' onClick={handleDel}>Usuń</Button>
                </Modal.Footer>
            </Modal>
         </>
    )
}

export default ModalQuestion;